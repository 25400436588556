export const windowGlobal = typeof window !== "undefined" && window;

export type TokenPathKeys = "authToken";

export const tokenPaths: Record<TokenPathKeys, string> = {
  authToken: "authToken",
};

export const cookieTrackingNames = {
  GA: "gatsby-gdpr-google-analytics",
};

export const getItem = (path: string) => windowGlobal?.localStorage?.getItem(path);

export const setItem = (path: string, item: string) =>
  windowGlobal?.localStorage?.setItem(path, item);

export const removeItem = (path: string) => windowGlobal?.localStorage?.removeItem(path);
