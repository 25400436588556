import { combineReducers } from "redux";
import mainApi from "./services/mainApi";

export interface RootState {
  //   reducer: ReducerState;
  mainApi: any;
}

const appReducer = combineReducers({
  // reducer,
  [mainApi.reducerPath]: mainApi.reducer,
});

export const RESET_STATE = "RESET_STATE";

const rootReducer = (state: RootState | undefined, action: any) => {
  if (action.type === RESET_STATE) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
