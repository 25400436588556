import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getStoredAuthToken } from '../../utils/auth-token'

export const API_URL = process.env.GATSBY_API_URL

export const baseQuery = fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers, { getState }) => {
        const token = getStoredAuthToken()
        if (token) {
            headers.set('Authorization', `Bearer ${token}`)
        }
        if (process.env.GATSBY_APP_ENV?.toLowerCase() === 'development') {
            headers.set('ngrok-skip-browser-warning', 'any value')
        }
        return headers
    },
})
