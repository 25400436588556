import { getItem, removeItem, setItem, tokenPaths } from './localStorage'

export const getStoredAuthToken = () => getItem(tokenPaths.authToken)

export const storeAuthToken = (token: string) => setItem(tokenPaths.authToken, token)

export const removeStoredAuthToken = () => {
    removeCurrentOrganisation()
    removeItem(tokenPaths.authToken)
}

export const getStoredAuthTokenAdmin = () => getItem(tokenPaths.authTokenAdmin)

export const storeAuthTokenAdmin = (token: string) => setItem(tokenPaths.authTokenAdmin, token)

export const removeStoredAuthTokenAdmin = () => removeItem(tokenPaths.authTokenAdmin)

export const setCurrentOrganisation = (organisation_id: string) =>
    setItem(tokenPaths.currentOrganisation, organisation_id)

export const getCurrentOrganisation = () => getItem(tokenPaths.currentOrganisation)

export const removeCurrentOrganisation = () => removeItem(tokenPaths.currentOrganisation)
