import { configureStore, isRejectedWithValue, MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import mainApi from "./services/mainApi";

export const rtkQueryTokenRedirect: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const rejectedAction = action as { payload?: { status: number; data?: { detail?: string } } };
    if (
      rejectedAction?.payload?.status === 401 &&
      rejectedAction?.payload?.data?.detail === "invalid_token"
    ) {
      // send to login screen here
    }
  }

  return next(action);
};

const createStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.GATSBY_APP_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(rtkQueryTokenRedirect).concat(mainApi.middleware),
  });
  setupListeners(store.dispatch);
  return store;
};

type ConfiguredStore = ReturnType<typeof createStore>;
type StoreGetState = ConfiguredStore["getState"];
export type RootState = ReturnType<StoreGetState>;
export type AppDispatch = ConfiguredStore["dispatch"];

export default createStore;
