import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./helpers";

export const DEVICES_TAG = "devices";
export const TagAbstractIds = {
  LIST: "LIST",
};

const mainApi = createApi({
  reducerPath: "mainApi",
  baseQuery,
  tagTypes: [DEVICES_TAG],
  endpoints: () => ({}),
  keepUnusedDataFor: 86400 * 30, // 1 month
});

export default mainApi;
